<template>
  <div class="message">
    <h3 class="sub-title">Welcome Message</h3>
    <p>Dear colleagues and friends,</p>
    <p class="text-justify">It is my great pleasure and honor to invite you to the next annual meeting of ASCVTS. ASCVTS 2024 is scheduled to be held from 23rd of May to 26th of May in Wuhan, China. I am looking forward to holding the 2024 annual meeting as an in-person event.</p>
    <p class="text-justify">What we went through during past decades showed that communication make excellence. In order to enhance the cooperation between the cardiothoracic surgeons world-wide and make primary innovations in different field. The theme of ASCVTS 2024, “Cooperation, Innovation, Excellence”, reflects our resolution that the pursuit of high standards of patient care and academic excellence should be continued with stronger solidarity.</p>
    <p class="text-justify">On behalf of the organizing committee of ASCVTS 2024, I promise that our best efforts will be made to make the meeting a highly scientific and educational one. In continuity with the previous annual meetings, there will be the postgraduate course sponsored by the American Association for Thoracic Surgery (AATS). We are also planning to organize symposia and sessions joined by other societies such as the Society of Thoracic Surgeons (STS) and the European Association for Cardio-Thoracic Surgery (EACTS). More than all, while the gate is widely open to participants from every corner of the world, we will try to provide more Asian surgeons with a larger platform for sharing their experiences and innovative ideas.</p>
    <p class="text-justify">I have no doubt that Wuhan is a perfect place as the host city of ASCVTS 2024. The coexistence of tradition and modernity alongside the Yangtze riverside scenic beauties symbolizes the industrial dynamicity and cultural diversity of China. With the unsparing auspices of the Chinese Association of Cardiovascular Surgeons and Chinese Society for Thoracic and Cardiovascular Surgery, the organizing committee is going to arrange for social events which will leave an unforgettable memory on the participants for sure.</p>
    <p class="text-justify">Please make a note in your calendar to join us in Wuhan in May. I sincerely hope that ASCVTS 2024 will be a festival in which we sit face-to-face again to celebrate reunion of the annual meeting and stand hand-in-hand to find primary innovations to make excellence in cardiothoracic surgery.</p>
    <p>Sincerely yours,</p>
    <p>&nbsp;</p>
    <div class="bottom">
      <div class="bottom-lt">
        <img src="@/assets/img/about/nianguodong.png">
      </div>
      <div class="bottom-rt">
        <p><img class="img-fluid" src=""></p>
        <h3 class="pre-author">Nianguo Dong, Congress President, the 32<sup>nd</sup> Annual Meeting of ASCVTS </h3>
        <span class="pre-subtext">
          Union Hospital, Huazhong University of Science and Technology<br/>
          Director of Cardiovascular Surgery Department, Organ Transplant Center, Institute Cardiovascular Disease<br/>
          President, Cardiovascular Surgery, CMDA<br/>
          Vice Chairman, Thoracic and Cardiovascular Surgery, CSTCS<br/>
          Vice Chairman, Expert Committee, NCCQI<br/>
          ASCVTS Councilor, AATS member, ISMICS member
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.message {
  .sub-title {
    font-weight: 900;
    font-size: 27px;
    line-height: normal;
    margin: 30px 0px 20px 0px;
    padding-bottom: 10px;
    border-bottom: solid 1px #ddd;
  }
  p {
    font-family: "PublicSans Regular";
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased !important;
    line-height: 26px;
    font-size: 16px;
    text-align: justify;
  }
  .bottom {
    display: flex;
    .bottom-lt {
      flex: 0 0 25%;
      img {
        width: 150px;
      }
    }
    .bottom-rt {
      flex: 0 0 75%;
      img {
        height: 50px;
      }
      .pre-author {
        font-size: 24px;
        margin-bottom: 15px;
        font-weight: 700;
        display: block;
      }
      .pre-subtext {
        font-family: "PublicSans Regular";
      }
    }
    @media (max-width:768px) {
      display: block;
      .bottom-lt {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>